export function getSegmentedRoute(path: string) {
    return "/community/:communityAddress" + path;
}

export function getSegmentedCommunityAddress(): string {
    const segments = window.location.pathname.split("/").filter(s => s);
    return segments[1];
}

export function getSegmentedPath(path: string) {
    return "/community/" + getSegmentedCommunityAddress() + path;
}

export function getUnsegmentedPath() {
    return "/community/" + window.location.pathname.split("/").slice(2).join("/");
}

export function segmentedPathStartsWith(path: string) {
    return getUnsegmentedPath().startsWith(path);
}
